import React, { memo } from "react";
import { Handle, Position } from "reactflow";

export default memo(({ id, data }) => {
  let theColor = data.hasOwnProperty('color') ? data.color : "#5893f9";
  console.log('the color is', theColor)
  console.log('the data is', data)
  const colorfulStyle = {
    "--colorful": theColor
  }
  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className="wrapper gradient" style={colorfulStyle}>
        <div className="inner">
          <>
            <label>{data.label}</label>
          </>
        </div>
      </div>
      <Handle type="input" position={Position.Bottom} />
    </>
);
});
