import Pipeline from "./components/Pipeline";
import Hero from "./components/Hero";
import Skillblock from "./components/Skillblock";

export default function App() {
  return (
    <div>
      <Hero />
      <Pipeline />
      <Skillblock />
    </div>
  );
}
