import React from "react";

class Skillblock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: [
        { skill: "kubernetes", path: "images/k8s.svg", level: "proficient" },
        { skill: "Jenkins", path: "images/jenkins.svg", level: "proficient" },
        { skill: "AWS", path: "images/aws.svg", level: "proficient" },
        { skill: "NPM", path: "images/npm.svg", level: "proficient" },
        { skill: "Terraform", path: "images/tf.svg", level: "proficient" },
        { skill: "Docker", path: "images/docker.svg", level: "proficient" },
        { skill: "Git", path: "images/git.svg", level: "proficient" },
        { skill: "GitHub Actions", path: "images/gh.svg", level: "proficient" },
        { skill: "Python", path: "images/python.svg", level: "proficient" },
        { skill: "Golang", path: "images/golang.svg", level: "familiar" },
        { skill: "Node", path: "images/node.svg", level: "proficient" },
        { skill: "Ansible", path: "images/ansible.svg", level: "proficient" },
        { skill: 'React', path: 'images/react.svg', level: 'learning'},
      ],
    };
  }

  render() {
    return (
      <div className="cards container-c">
        {
          this.state.skills.map((value) => {
            return (
              <div key={value.skill} className="container-card">
                <img src={value.path} alt="" />
                <p>{value.skill}</p>
                <span className={value.level}></span>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default Skillblock;
