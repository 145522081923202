import { memo } from "react";
import ReactFlow, { Background, Controls } from "reactflow";
import "reactflow/dist/style.css";
import colorful from "./FlowNodes/colorful";

const nodeTypes = {
  colorful: colorful,
};

const nodes = [
  {
    id: "1",
    data: { label: "Git Event", color: "#123456" },
    position: { x: 200, y: 0 },
    type: "colorful",
  },
  {
    id: "2",
    data: { label: "Container Build" },
    position: { x: 100, y: 100 },
  },
  {
    id: "3",
    data: { label: "Unit Test" },
    position: { x: 300, y: 100 },
  },
  {
    id: "4",
    data: { label: "Container Test", color: "#74c69d" },
    position: { x: 200, y: 200 },
    type: "colorful",
  },
  {
    id: "5",
    data: { label: "Push Image to Registry" },
    position: { x: 200, y: 275 },
  },
  {
    id: "6",
    data: { label: "Deploy to Kubernetes" },
    position: { x: 200, y: 350 },
    type: "colorful",
    style: { borderColor: "#326ce5" },
  },
];

const edges = [
  // animated edge
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: true,
    style: { stroke: "#49b675" },
  },
  {
    id: "e1-3",
    source: "1",
    target: "3",
    animated: true,
    style: { stroke: "#49b675" },
  },
  {
    id: "e2-4",
    source: "2",
    target: "4",
    animated: false,
    style: { stroke: "#98d0ec" },
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: false,
    style: { stroke: "#98d0ec" },
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    animated: true,
    style: { stroke: "#49b675" },
  },
  {
    id: "e4-6",
    source: "5",
    target: "6",
    animated: true,
    style: { stroke: "#49b675" },
  },
];

function Pipeline () {
  return (
    <div className="contact tooltip" style={{ height: 300 }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        elementsSelectable={false}
        nodesDraggable={false}
        fitView
        style={{
          borderRadius: "3px",
          borderStyle: "solid",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
          borderWidth: "1px",
        }}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}

export default memo(Pipeline);
