import React from "react";
import ReactRotatingText from "react-rotating-text";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enjoyments: [
        "Chicken.",
        "Pipelines.",
        "Crepes.",
        "Programming.",
        "Math.",
        "Pancakes!",
        "Containers.",
      ],
      blurb: `Computers are the closest thing to magic, thats what makes
it all so exciting! Below you can find some tools and technologies
that I am comfortable and have fun working with.`,
    };
  }

  render() {
    return (
      <div className="intro container-a">
        <h1>
          Hey there, my name's
          <br />
          <span className="highlight">Jose</span>, and I enjoy
          <br />
          <span
            className="txt-rotate highlight"
            data-period="2000"
            data-rotate={this.state.enjoyments}
          >
            <ReactRotatingText items={this.state.enjoyments} />
          </span>
        </h1>
        <p>{this.state.blurb}</p>
        <p>
          ( <span className="proficient"></span> is proficient,{" "}
          <span className="familiar"></span> is familiar,{" "}
          <span className="learning"></span> is learning )
        </p>
      </div>
    );
  }
}

export default Hero;
